import { template as template_a1322bda01ee46dfb87cc3a0e7722ecb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_a1322bda01ee46dfb87cc3a0e7722ecb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
