import { template as template_5445bd9b47874583b3712f2dae0a82d1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5445bd9b47874583b3712f2dae0a82d1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
