import { template as template_124d9e4bc8c64ae696391b1b73c9e5a2 } from "@ember/template-compiler";
const FKLabel = template_124d9e4bc8c64ae696391b1b73c9e5a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
