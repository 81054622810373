import { template as template_5428f04f0071488ea12b4a68a4a8fb1d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_5428f04f0071488ea12b4a68a4a8fb1d(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
